
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import credits from '@/services/credits'

import { currencyFilter } from '@/utils/string'

import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { EventBus } from '@/utils/eventBus'
import { Credit } from '@/models/dto/Credit'
import sidebar from '@/store/modules/sidebar'
import Placeholder from '@/views/Placeholder.vue'
import { TableAction } from '@/models/TableAction'
import auth from '@/store/modules/auth'

@Component({
  components: { CUCollectionTable },
})
export default class CreditsList extends mixins(DateMixin) {
  mounted(): void {
    EventBus.$on('update:open-reservation', this.openReservation)
  }

  beforeDestroy(): void {
    EventBus.$off('update:open-reservation', this.openReservation)
  }

  tableView = credits.tableView
  tableItemCount = 0

  openReservation(row: Credit): void {
    sidebar.push({
      component: Placeholder,
      title: `Reservation ID ${row.reservation.managedId}`,
    })
  }

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  actions: TableAction[] = []

  columns: DataTableColumn[] = [
    {
      _t_id: '7b37c1a4-e49a-497a-b442-253197c60981',
      text: 'Transaction ID',
      value: 'transactionId',
    },
    {
      _t_id: '458fcf45-00ad-473d-b3cc-dfee9c6eac2f',
      text: 'Transaction Type',
      value: 'transactionType',
    },
    {
      _t_id: '60a52f28-95b5-4c9d-9f51-225429d4fa7b',
      text: 'Amount',
      value: 'amount',
      computedText: (row): string => currencyFilter(row.amount),
    },
    {
      _t_id: '0befdc4f-067b-4f34-b445-9ca306f47700',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row): string =>
        this.formatMediumDate(row.createdOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: '6ac7dc8d-1494-4fb3-89d0-9c56c4599260',
      text: 'Reservation ID',
      value: 'reservation/managedId',
      type: 'link',
      clickEvent: 'update:open-reservation',
    },
    {
      _t_id: '9ec67bfb-fecf-43fd-bc01-a4a6b9614def',
      text: 'Payment ID',
      value: 'paymentId',
    },
  ]
}
